export const environment = {
  production: true,
  languageUrl: 'https://estate.preview.estatepro.de',
  baseUrl: 'https://api.estate.preview.empro.ai',
  actionApiUrl: 'https://api.action.preview.empro.ai/v1',
  permissionApiUrl: 'https://api.permissions.preview.empro.ai',
  userApiUrl: 'https://nest.api.user.preview.empro.ai/v1',
  websocketUrl: 'wss://main-estate-websocket.preview.empro.ai',
  is24RestApiUrl: 'https://rest.sandbox-immobilienscout24.de/restapi/',
  ocmUrl: 'https://api.ocm.preview.empro.ai',
  estate_online_expose_pdf: true,
};
